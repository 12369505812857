@import "../theme/theme";

.cardDetailed {
  padding: 0 0 24px 0;
  border-bottom: 1px solid #232323;
  display: grid;
  grid-template-columns: 2fr 1fr;
  column-gap: 32px;
  .stats {
    // border: 1px solid red;
    ul {
      margin: 0;
      padding: 0;
    }
    ul li {
      list-style: none;
      padding: 8px 0;
      border-bottom: 1px solid #232323;
    }
  }
}
