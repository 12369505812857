@import "../theme/theme";

span.closed {
  color: #e00;
  border: 1px solid #e00;
}
span.open {
  color: #50e3c2;
  border: 1px solid #50e3c2;
}

span.closed,
span.open {
  text-transform: uppercase;
  font-size: x-small;
  padding: 4px 8px;
  border-radius: 2px;
  height: fit-content;
}
