@import "../theme/theme";

.tooltip {
  background: rgba(102, 102, 102, 0.1);
  border-radius: 8px;
}

.contents {
  margin: 32px 0;
  // max-width: 800px;
  .chartMeta {
    display: flex;
    margin: 0 0 16px 0;
    .companyName {
      padding: 0 0 0 16px;
      align-self: center;
      h2,
      h4 {
        margin-block-start: 0;
        margin-block-end: 0;
      }
      h4 {
        color: #666;
      }
    }
    .logo {
      width: 64px;
      height: 64px;
      position: relative;
      overflow: hidden;
      border-radius: 50%;
      align-self: center;
    }

    img {
      display: inline;
      margin: 0 auto;
      height: 100%;
      width: auto;
      filter: grayscale(1);
    }
  }
}
