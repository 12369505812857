@import "../theme/theme";

body {
  background-color: #0c0c0c;
  // background-color: #32323e;
  color: #fff;
}

.layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 32px;
  }
}
