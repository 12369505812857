@import "../theme/theme";

header {
  padding: 0 0 40px;
  border-bottom: 1px solid #888;
  margin: 0 0 32px;
}
.meta {
  display: flex;
  .date {
    margin: 0 16px 0 0;
    color: #888;
  }
}

.contents {
}
